import React from 'react';
import queryString from 'query-string';
import { compact } from 'lodash/fp';
import loadable from '@loadable/component';

const paths: Partial<Record<RouteKey, string>> = {
	home: '/',
	about: '/about',
	team: '/team',
	contact: '/contact',
	privacy: '/privacy',
	terms: '/terms',
	joinus: '/joinus',
	services: '/services',
	service: '/service/:slug',
	vacancies: '/vacancies',
	vacancy: '/vacancy/:slug',
	blog: '/blog',
	article: '/blog/:slug',
	myteam: '/myteam',
	softwareteam: '/myteam/softwareteam',
	supportteam: '/myteam/supportteam',
	wiki: '/wiki',
	wikiArticle: '/wiki/:slug',
	questionnaire: '/questionnaire',
	questionnaireBali: '/questionnaire-bali',
	contactUsForm: '/contactUsForm',
	sitemap: '/sitemap',

	/////////////////////////////////////////////////////

	home2: '/v2',
	whyus: '/v2/whyus',
	aboutus: '/v2/aboutus',
	gethired: '/v2/gethired',
	contactUs: '/v2/contactUs',

	/////////////////////////////////////////////////////
	notFound: '',
};

const routeComponents: Record<string, React.ComponentType<any>> = {
	home: loadable(() => import(`./pages/Home/Home`)),
	about: loadable(() => import('./pages/About/About')),
	team: loadable(() => import('./pages/Team/Team')),
	contact: loadable(() => import('./pages/Contact/Contact')),
	privacy: loadable(() => import('./pages/Privacy/Privacy')),
	terms: loadable(() => import('./pages/Terms/Terms')),
	joinus: loadable(() => import('./pages/JoinUs/JoinUs')),
	services: loadable(() => import('./pages/Services/Services')),
	service: loadable(() => import('./pages/Expertise/Expertise')),
	vacancies: loadable(() => import('./pages/Vacancies/Vacancies')),
	vacancy: loadable(() => import('./pages/Vacancy/Vacancy')),
	blog: loadable(() => import('./pages/Blog/Blog')),
	article: loadable(() => import('./pages/Article/Article')),
	myteam: loadable(() => import('./pages/MyTeam/MyTeam')),
	softwareteam: loadable(() => import('./pages/Softwareteam/Softwareteam')),
	supportteam: loadable(() => import('./pages/Softwareteam/Supportteam')),
	wiki: loadable(() => import('./pages/Wiki/Wiki')),
	wikiArticle: loadable(() => import('./pages/WikiArticle/WikiArticle')),
	questionnaire: loadable(() => import('./pages/Questionnaire/Questionnaire')),
	questionnaireBali: loadable(() => import('./pages/QuestionnaireBali/Questionnaire')),
	contactUsForm: loadable(() => import('./pages/ContactUsForm/ContactUsForm')),
	sitemap: loadable(() => import('./pages/Sitemap/Sitemap')),

	/////////////////////////////////////////////////////

	home2: loadable(() => import(`./pages_v2/Home/Home`)),
	whyus: loadable(() => import('./pages_v2/WhyUs/WhyUs')),
	aboutus: loadable(() => import('./pages_v2/AboutUs/AboutUs')),
	gethired: loadable(() => import('./pages_v2/GetHired/GetHired')),
	contactUs: loadable(() => import('./pages_v2/ContactUs/ContactUs')),

	/////////////////////////////////////////////////////
	notFound: loadable(() => import('./pages/NotFound/NotFound')),
};

type RouteKey = keyof typeof routeComponents;

export default function getPlainRoutes(
	routeOrder: ReadonlyArray<RouteKey> = [
		'home',
		'about',
		'team',
		'contact',
		'privacy',
		'terms',
		'joinus',
		'services',
		'service',
		'vacancies',
		'vacancy',
		'blog',
		'article',
		'myteam',
		'softwareteam',
		'supportteam',
		'wiki',
		'wikiArticle',
		'questionnaire',
		'questionnaireBali',
		'contactUsForm',
		'sitemap',

		/////////////////////////////////////////////////////
		'home2',
		'whyus',
		'aboutus',
		'gethired',
		'contactUs',
		/////////////////////////////////////////////////////

		'notFound',
	]
) {
	return compact(
		routeOrder.map((key) => {
			const path = paths[key];

			return path !== undefined
				? {
						path,
						component: routeComponents[key],
						exact: true,
				  }
				: undefined;
		})
	);
}

export function getPath(routeKey: RouteKey, params?: RouteParams, query?: object, state?: object) {
	const path = routeKey;
	if (path === undefined) return null;
	const interpolated = interpolateParams(path, params);
	const prefixed = `${interpolated}`;
	return { pathname: prefixed, search: query ? `?${queryString.stringify(query)}` : '', state };
}

type RouteParams = Record<string, string | number>;

export function interpolateParams(path: string, params?: RouteParams) {
	const parts = path.split('/');
	const replaced = parts.map((part) => {
		if (!part.startsWith(':')) return part;

		const paramKey = part.slice(1, part.includes('(') ? part.indexOf('(') : undefined);

		if (!params || !params[paramKey]) throw new Error(`Missing param '${paramKey}' for path '${path}'`);
		return params[paramKey];
	});

	return replaced.join('/');
}
